<template>
    <div style="text-align: left;">
        <el-card>
            <el-tabs v-model="adImgFlag" @tab-click="handleClick">
                <el-tab-pane label="电脑端广告管理" name="0"></el-tab-pane>
                <el-tab-pane label="移动端广告管理" name="1"></el-tab-pane>
            </el-tabs>
            <div class="df-jc-s-b df-ai-c top">
                <div>幻灯片（{{list&&list.length}}）</div>
                <div>
                    <el-button type="text" icon="el-icon-circle-plus-outline" @click="$oucy.go('/enter/shopSave?adImgFlag='+adImgFlag)">添加</el-button>
                </div>
            </div>
            <div class="df-jc-s-b" v-if="list && list.length">
                <div v-for="(item,index) of list" v-dragging="{ item: item, list: list, group: 'item' }" class="box" :key="item.id" @click="gotoEdit(item)">
                    <img :src="$oucy.ossUrl+item.adImg" alt="" class="adImg">
                    <div class="center line-1" title="点击进入编辑 长按拖动排序" v-if="list && list.length>1">点击进入编辑 长按拖动排序</div>
                    <div class="br">第{{index+1}}张</div>
                </div>
            </div>
            <div class="text-center" v-else>
                <img src="@/assets/images/photo/class_not.png">
                <div class="f14 c-2 m-b-20">
                    当前还未有任何广告
                </div>
                <el-button type="default" size="mini" @click="$oucy.go('/enter/shopSave?adImgFlag='+adImgFlag)">立即添加</el-button>
            </div>
        </el-card>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import { adEnterprise } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "Photo",
    components: {},
    data() {
        return {
            list: [],
            adImgFlag: 0,
            setTimeoutId:null,
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.adImgFlag=this.$route.query.adImgFlag||0
        this.queryAdEnterprises()

        // 位置改变回调
        this.$dragging.$on('dragged', ({ value }) => {
            console.log(value.item)
            console.log(value.list)
            console.log(value.group)
           this.setTimeoutId && clearTimeout(this.setTimeoutId)
        })
        // 结束回调
        this.$dragging.$on('dragend', (res) => {
            console.log(res);
            this.updateIndex()
        })
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0)
    },
    methods: {
        // 查询
        queryAdEnterprises() {
            adEnterprise.queryAdEnterprises({ adImgFlag: this.adImgFlag * 1, enterpriseId: this.enterpriseId }).then(res => {
                if (res) {
                    this.list = res
                    this.setIndex()
                } else {}
            })
        },
        handleClick() {
            this.$oucy.replace('/enter/shop?adImgFlag='+this.adImgFlag)
            this.list =[]
            this.queryAdEnterprises()
        },
        updateIndex(){
           this.setTimeoutId && clearTimeout(this.setTimeoutId)
           this.setTimeoutId= setTimeout(()=>{
                for (var i = 0; i < this.list.length; i++) {
                    if(this.list[i].index!=i){
                        this.list[i].index=i
                        this.updateAdEnterprise(this.list[i])
                    }
                }
                this.setTimeoutId=null
            },2000)
        },
        updateAdEnterprise(v) {
            if(!v.id)return
            v.enterpriseId = this.enterpriseId
            v.adImgFlag = this.adImgFlag*1
            adEnterprise.updateAdEnterprise(v).then(res => {
            })
        },
        setIndex(list = this.list) {
            for (let i = 0; i < list.length; i++) {
                list[i].index = i
            }
        },
        gotoEdit(v){
            console.log(v)
            this.$oucy.go(`/enter/shopSave?adImgFlag=${this.adImgFlag}&id=${v.id}`)
        }
    }
}
</script>
<style scoped lang="less">
.top {
    background: #F2F4F6;
    padding: 10px;
    font-size: 14px;
}

.box {
    margin: 5px;
    flex: 1;
    position: relative;
    border: 2px solid #fff;
    font-size: 0;

    &:hover {
        border: 2px solid #2090FF;

        .adImg {
            filter: brightness(50%);
        }

        .center {
            display: block;
        }
    }
}

.adImg {
    max-width: 100%;
    /*height: auto;*/
}

.center,
.br {
    color: #fff;
    position: absolute;
    font-size: 12px;
    text-shadow:2px 2px 4px #000;
}

.center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
}

.br {
    right: 10px;
    bottom: 10px;
}
</style>